import { CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BillStatus, OrderItemStatus, PaymentStatus, SHIPPING_FEE } from '../../consts';
import { useMenuContext } from '../../context/MenuContext';
import { useOrderContext } from '../../context/OrderContext';
import FindRider from '../OrderStatus/components/FindRider';
import RiderInFor from '../OrderStatus/components/RiderInFor';
import { ReactComponent as CreditCardIcon } from '../../../src/assets/icon/credit-card.svg';
import { ReactComponent as DeliveryIcon } from '../../../src/assets/icon/delivery.svg';
import { ReactComponent as DoneIcon } from '../../../src/assets/icon/done.svg';
import { ReactComponent as LikeIcon } from '../../../src/assets/icon/like.svg';
import { ReactComponent as MobileCardIcon } from '../../../src/assets/icon/mobile-payment-card.svg';
import { ReactComponent as PrepareIcon } from '../../../src/assets/icon/prepare.svg';
import { ReactComponent as Arrow } from '../../../src/assets/icon/arrow-left.svg';
import { ReactComponent as Vector } from '../../../src/assets/icon/vector838.svg';
import queryString from 'query-string';
import Toast from '../../components/Toast';
import PaymentBacklog from '../OrderStatus/components/PaymentBacklog';
import { formatPriceItem } from '../../utils';

export type ToastContent = { type: 'error' | 'success' | 'info'; content: string; duration?: number };

const OrderDetail: React.FC = () => {
  const { t } = useTranslation();
  const { listRestaurantData } = useMenuContext();
  const { loadingOrder, orders, updateSeatId } = useOrderContext();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const navigate = useNavigate();
  const [orderDetail, setOrderDetail] = useState<any>(null);
  const params = useParams<{ id: string }>();
  const orderId = params.id;

  const [openToast, setOpenToast] = useState(false);
  const [isToastShown, setIsToastShown] = useState(false);
  const toast = useRef<ToastContent>({
    type: 'success',
    content: '',
  });

  const showToast = (content: ToastContent) => {
    toast.current = content;
    setOpenToast(true);
  };

  useEffect(() => {
    if (!isToastShown && orders && !loadingOrder) {
      const paymentStatus = queryParams.status;
      if (paymentStatus === PaymentStatus.CAPTURED) {
        showToast({
          content: 'Payment successful!',
          type: 'success',
          duration: 3000,
        });
      } else if (paymentStatus === PaymentStatus.CANCELLED) {
        showToast({
          content: 'Payment cancelled.',
          type: 'info',
          duration: 3000,
        });
      } else if (paymentStatus === PaymentStatus.FAILED) {
        showToast({
          content: 'Payment failed.',
          type: 'error',
          duration: 3000,
        });
      }
      setIsToastShown(true);
      const { codeId, status, ...newQueryParams } = queryParams;
      const newQueryString = queryString.stringify(newQueryParams);
      navigate(`?${newQueryString}`, { replace: true });
    }
  }, [queryParams, orders, loadingOrder]);

  const orderStatuses = {
    [BillStatus.NOT_CLEARED]: 0,
    [OrderItemStatus.PREPARING]: 1,
    [OrderItemStatus.READY_FOR_PICK_UP]: 2,
    [OrderItemStatus.DELIVERING]: 3,
    [OrderItemStatus.DELIVERED]: 4,
  };

  const handleBackToMenu = async () => {
    localStorage.setItem('flag', true.toString());
    const currentOrderId = sessionStorage.getItem('orderId') ?? '';
    const seatID = queryParams.seatID as string;
    let newOrderId = '';
    if (orders.findIndex((o) => o.id === currentOrderId) > -1) {
      newOrderId = await updateSeatId(seatID);
    } else {
      newOrderId = currentOrderId;
    }
    const neworderDetailLink = queryString.stringifyUrl({
      url: `/neworder/${newOrderId}`,
      query: { ...queryParams, tab: 'orderHistory' },
    });
    navigate(neworderDetailLink);
  };

  useEffect(() => {
    if (orders && !loadingOrder) {
      const data = orders.find((order) => order.id === orderId);
      if (data) {
        const restaurant = listRestaurantData.find((restaurant) => restaurant.id === data?.restaurant_id);
        setOrderDetail({ ...data, restaurant });
      } else {
        const homeLink = queryString.stringifyUrl({
          url: `/`,
          query: queryParams,
        });
        navigate(homeLink);
      }
    }
  }, [listRestaurantData, orderId, orders, loadingOrder]);

  const price = useMemo(() => {
    if (orderDetail) {
      const totalPrice =
        (orderDetail.foods?.reduce((total: any, food: any) => total + (parseFloat(food.item_subtotal) || 0), 0) || 0) +
        (orderDetail.promotion_foods?.reduce((total: any, food: any) => total + (parseFloat(food.item_subtotal) || 0), 0) || 0);
      const totalItem =
        (orderDetail.foods?.reduce((total: any, food: any) => total + parseFloat(food.item_quantity), 0) || 0) +
        (orderDetail.promotion_foods?.reduce((total: any, food: any) => total + parseFloat(food.item_quantity), 0) || 0);
      const totalPayment = totalPrice + SHIPPING_FEE;
      return { totalPrice: totalPrice?.toLocaleString('jp'), totalItem, totalPayment: totalPayment?.toLocaleString('jp') };
    }
  }, [orderDetail]);

  const statusHandler = useMemo(() => {
    if (orderDetail) {
      if (orderDetail?.bill_status === BillStatus.NOT_CLEARED) {
        return {
          status: t('PREPARING'),
          icon: <LikeIcon />,
          label: t('PREPARING'),
          description: t('UNPAID_LABEL_DESCRIPTION'),
          className: 'bg-error-50 text-error-700',
        };
      }
      switch (orderDetail.order_status) {
        case OrderItemStatus.PREPARING:
          return {
            status: t('PREPARING'),
            icon: <LikeIcon />,
            label: t('PREPARING'),
            description: t('PREPARING_LABEL_DESCRIPTION'),
            className: 'bg-error-50 text-error-700',
          };
        case OrderItemStatus.READY_FOR_PICK_UP:
          return {
            status: t('READY_FOR_PICK_UP'),
            icon: <PrepareIcon />,
            label: t('READY_FOR_PICK_UP'),
            description: t('READY_FOR_PICK_UP_LABEL_DESCRIPTION'),
            className: 'bg-warning-50 text-warning-700',
          };
        case OrderItemStatus.DELIVERING:
          return {
            status: t('DELIVERING'),
            icon: <DeliveryIcon />,
            label: t('DELIVERING'),
            description: t('DELIVERING_LABEL'),
            className: 'bg-blueLine-50 text-blueLine-700',
          };
        case OrderItemStatus.DELIVERED:
          return {
            status: t('DELIVERED'),
            icon: <DoneIcon />,
            label: t('DELIVERED_LABEL'),
            description: t('DELIVERED_LABEL_DESCRIPTION'),
            className: 'bg-success-50 text-success-700',
          };
        default:
          return {
            status: t('DELIVERED'),
            icon: <DoneIcon />,
            label: t('DELIVERED_LABEL'),
            description: t('DELIVERED_LABEL_DESCRIPTION'),
            className: 'bg-success-50 text-success-700',
          };
      }
    }
    return null;
  }, [orderDetail]);

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {loadingOrder ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <CircularProgress color="success" size={65} />
        </div>
      ) : (
        <div className="w-[auto] min-h-[100vh] bg-black-0">
          {orderDetail && (
            <div className="max-w-[672px] min-h-[100vh] mx-auto bg-white">
              <div className="px-4 pt-6 pb-4 flex justify-center items-center gap-4 flex-col border-b relative">
                {statusHandler?.icon}
                <Arrow className="absolute top-6 left-6 cursor-pointer" onClick={() => handleBackToMenu()} />
                <div className="flex flex-col justify-center items-center text-black-800 font-semibold text-xl leading-[30px]">
                  <p className="text-black-500 font-normal text-base leading-6">{statusHandler?.label}</p>
                  {statusHandler?.description}
                </div>
                <div className="w-full flex gap-4 justify-between">
                  {Object.values(orderStatuses).map((status, index) => (
                    <div
                      key={index}
                      className={`w-1/4 h-2 ${
                        (orderDetail?.bill_status === BillStatus.NOT_CLEARED && index === 0) ||
                        (orderDetail?.bill_status === BillStatus.CLEARED && orderStatuses[orderDetail?.order_status as OrderItemStatus] >= index)
                          ? 'bg-green-500'
                          : 'bg-neutral-300'
                      } rounded-[9px]`}
                    ></div>
                  ))}
                </div>
                {orderDetail?.bill_status === BillStatus.NOT_CLEARED && <PaymentBacklog orderDetail={orderDetail} />}
                {orderDetail?.bill_status === BillStatus.CLEARED && (orderDetail && orderDetail.rider ? <RiderInFor riderInfor={orderDetail.rider} /> : <FindRider />)}
              </div>
              <div className="p-4 flex flex-col items-center justify-center gap-2 border-b">
                <p className={`py-[2px] px-[10px] rounded-2xl text-sm font-medium leading-5 ${statusHandler?.className}`}>{statusHandler?.status}</p>
                <div className="px-4 py-3 flex flex-col items-center justify-center gap-1 rounded-lg bg-green-50 w-full">
                  <p className="text-black-500 font-medium text-sm leading-5">{t('HOME_ORDERID_TITLE')}</p>
                  <p className="text-black-800 font-semibold text-3xl leading-[38px]">{orderId}</p>
                </div>
                <p className="text-green-700 text-sm font-medium leading-5">{t('ORDER_STATUS_TITLE_RIDER')}</p>
              </div>
              <div className="border-b">
                <div className="my-3 mx-4 p-3 flex gap-4 rounded-lg border border-dashed border-neutral-300  bg-[#F9FAFB]">
                  <div className="flex flex-col gap-1 w-[60%]">
                    <p className="text-black-500 font-medium text-sm leading-5">{t('ORDER_STATUS_TITLE')}</p>
                    <p className="text-black-800 font-medium text-sm leading-5">{orderDetail?.restaurant?.name}</p>
                  </div>
                  <Vector className="h-full" />
                  <div className="flex flex-col gap-1 ">
                    <p className="text-black-500 font-medium text-sm leading-5">{t('ORDER_STATUS_SEAT_NUMBER')}</p>
                    <p className="text-black-800 font-medium text-sm leading-5">{orderDetail && orderDetail.seat_number}</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between py-3 px-4 text-sm leading-5">
                <p className="text-black-500 font-medium ">{t('DELIVERY_ORDER_SUMMARY')}</p>
              </div>
              <div className="">
                {orderDetail?.foods &&
                  orderDetail?.foods.map((item: any, index: any) => (
                    <div key={index} className="flex justify-between gap-4 p-4 border-b">
                      <div className="flex-1 flex gap-2">
                        <p className="text-xs not-italic font-medium leading-[18px] text-green-500">{item.item_quantity}x</p>
                        <div className="flex flex-col gap-2">
                          <p className="text-black-800 text-xs not-italic font-medium leading-[18px]">{item.item_name}</p>
                          <div className="flex gap-2 border-b pb-1 text-[10px] not-italic font-normal leading-[150%] text-black-500">
                            {item.item_customize_settings.map((topping: any, index: any) => (
                              <React.Fragment key={index}>
                                <p>{topping.name}</p>
                                {index !== item.item_customize_settings.length - 1 && <span>|</span>}
                              </React.Fragment>
                            ))}
                          </div>
                          <p className="text-black-400 text-xs font-normal leading-[18px]">{item.item_note}</p>
                        </div>
                      </div>
                      <p className="w-fit text-black-500 text-xs font-normal leading-[18px]">¥ {(parseFloat(item.item_subtotal) || 0)?.toLocaleString('jp')} </p>
                    </div>
                  ))}
                {orderDetail?.promotion_foods &&
                  orderDetail?.promotion_foods.map((item: any, index: any) => (
                    <div key={index} className="flex justify-between gap-4 p-4 border-b">
                      <div className="flex-1 flex gap-2">
                        <p className="text-xs not-italic font-medium leading-[18px] text-green-500">{item.item_quantity}x</p>
                        <div className="flex flex-col gap-2">
                          <p className="text-black-800 text-xs not-italic font-medium leading-[18px]">{item.item_name}</p>
                          <div className="flex gap-2 border-b pb-1 text-[10px] not-italic font-normal leading-[150%] text-black-500">
                            {item.item_customize_settings.map((topping: any, index: any) => (
                              <React.Fragment key={index}>
                                <p>{topping.name}</p>
                                {index !== item.item_customize_settings.length - 1 && <span>|</span>}
                              </React.Fragment>
                            ))}
                          </div>
                          <p className="text-black-400 text-xs font-normal leading-[18px]">{item.item_note}</p>
                        </div>
                      </div>
                      <p className="w-fit text-black-500 text-xs font-normal leading-[18px]">¥ {(parseFloat(item.item_subtotal) || 0)?.toLocaleString('jp')} </p>
                    </div>
                  ))}
              </div>
              <div className="py-3 px-4">
                <p className="text-gray-500 text-sm font-medium leading-5">{t('DELIVERY_ORDER_PAYMENT')}</p>
              </div>
              <div className="flex justify-between py-2 px-4">
                <p className="text-gray-700 text-xs font-normal leading-[18px]">{t('ORDER_PRICE')}</p>
                <p className="text-black-500 text-xs font-normal leading-[18px]">¥ {formatPriceItem(price?.totalPrice)}</p>
              </div>
              <div className="flex justify-between py-2 px-4">
                <p className="text-gray-700 text-xs font-normal leading-[18px]">{t('ORDER_DELIVERY_FEE')}</p>
                <p className="text-black-500 text-xs font-normal leading-[18px]">¥ {formatPriceItem(SHIPPING_FEE)}</p>
              </div>
              <div className="flex justify-between py-2 px-4 border-b">
                <p className="text-gray-700 text-xs font-normal leading-[18px]">{t('ORDER_PAYMENT_METHOD')}</p>
                <div className="flex gap-1 items-center">
                  {orderDetail?.payment_method === 'CREDIT_CARD' ? <CreditCardIcon /> : <MobileCardIcon />}
                  <p className="text-black-500 text-xs font-normal leading-[18px]">
                    {orderDetail?.payment_method === 'CREDIT_CARD' ? t('CREDIT_CARD') : t('MOBILE_PAYMENT')}
                  </p>
                </div>
              </div>
              <div className="p-4 flex justify-between text-black-800 text-sm font-semibold leading-5">
                <p>{t('ORDER_TOTAL_PAYMENT')}</p>
                <p>¥ {formatPriceItem(price?.totalPayment)}</p>
              </div>
            </div>
          )}
          <Toast
            content={toast.current?.content}
            variant={toast.current?.type}
            open={openToast}
            onClose={() => {
              setOpenToast(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default OrderDetail;
