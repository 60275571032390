import React from 'react';
import { ReactComponent as LocationIcon } from '../../../assets/icon/location.svg';
import { useTranslation } from 'react-i18next';

interface FoodItemProps {
  name: string;
  itemCount: number;
  location: string;
  describe?: string;
  image: string;
  price?: number;
  enable_sales_count: boolean;
}

const RestaurantItem: React.FC<FoodItemProps> = ({ name, itemCount, location, describe, image, price, enable_sales_count }) => {
  const { t } = useTranslation();
  return (
    <div className="flex-shrink-0 min-w-[140px]">
      <div className="flex flex-col items-center justify-center bg-white px-4 pt-3 rounded-lg shadow-md min-w-[140px]">
        <img src={image} alt={name} className="w-[140px] h-[140px] shrink-0 rounded-lg" />
        <div className="mt-2 w-[130px]">
          <p className="text-gray-800 text-sm font-medium not-italic leading-5 text-clip w-[130px] overflow-hidden">{name}</p>
          {enable_sales_count && (
            <div className="text-gray-500 text-xs font-medium not-italic leading-[18px] flex items-center">
              <p>{price}</p>
              <p className="font-light">{t('HOME_SOLD_ITEM', { item: itemCount })}</p>
              <p className="p-1">|</p>
              <LocationIcon />
              <p className="p-1">
                {location} {t('ZONE')}
              </p>
            </div>
          )}
          {describe && (
            <p className="text-black-400 text-[10px] not-italic font-light leading-[15px] text-ellipsis w-[130px] whitespace-nowrap overflow-hidden">{describe}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RestaurantItem;
