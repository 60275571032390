import React, { useEffect, useRef, useState } from 'react';
import backgroundImg from '../../../../stadium.png';
import ListFoodSuggested from '../../../../components/ListFoodSuggested';
import Restaurant from '../../../../components/Restaurant';
import { ReactComponent as SearchIcon } from '../../../../assets/icon/search.svg';
import { useMenuContext } from '../../../../context/MenuContext';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { RestaurantWithFoodType, SearchResultType, listRestaurantType } from '../../../../interfaces/types';
import SearchResult from './Search/SearchResult';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { isRestaurantOnline } from '../../../../utils';

interface HomeTabProps {}

const HomeTab: React.FC<HomeTabProps> = () => {
  const { listRestaurantWithFood, loading, listRestaurantData, stadiumData, seatInformation } = useMenuContext();
  const { t } = useTranslation();
  const orderId = sessionStorage.getItem('orderId');
  const [searchResult, setSearchResult] = useState<SearchResultType>();
  const [input, setInput] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase();
    setInput(inputValue);
    const onlineRestaurants = listRestaurantData.filter(isRestaurantOnline);
    const filteredFood = listRestaurantWithFood.filter((restaurant: RestaurantWithFoodType) => {
      for (const foodDetail of restaurant.food_details) {
        if (foodDetail.name.toLowerCase().includes(inputValue) && foodDetail.enabled == true) {
          return true;
        }
      }
      return false;
    });
    const filteredRestaurant = onlineRestaurants.filter((restaurant: listRestaurantType) => {
      if (restaurant.name.toLowerCase().includes(inputValue)) {
        return true;
      }
      return false;
    });
    setSearchResult({ restaurants: filteredRestaurant, foods: filteredFood });
  };

  const isInputEmpty = input.trim() === '';

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (listRestaurantData.length === 0 && listRestaurantWithFood.length === 0 && !loading) {
      const orderDetailLink = queryString.stringifyUrl({
        url: `/`,
        query: queryParams,
      });
      return navigate(orderDetailLink);
    }
  }, [loading, listRestaurantData, listRestaurantWithFood]);

  return (
    <div className="relative min-h-screen">
      {loading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <CircularProgress color="success" size={65} />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="relative flex flex-col">
            <div
              className="h-[150px] absolute top-0 left-0 w-full z-[1]"
              style={{ background: 'linear-gradient(180deg, #30893C 0%, rgba(52, 143, 64, 0.72) 69.6%, rgba(61, 159, 74, 0.00) 100%)' }}
            ></div>
            <div className="h-[150px] bg-no-repeat bg-cover" style={{ backgroundImage: `url(${backgroundImg})` }}>
              {stadiumData && stadiumData.service_name && (
                <div className="flex justify-center items-center pt-4">
                  <div className="flex gap-1 z-[2]">
                    <div className="text-white opacity-70 text-sm leading-5 not-italic font-medium">{stadiumData.service_name}</div>
                  </div>
                </div>
              )}
              <div className="flex items-start px-4 pt-2 justify-between">
                <div className="flex flex-col gap-1 z-[2]">
                  <p className="text-white opacity-70 text-sm leading-5 not-italic font-medium">{t('HOME_SEAT_TITLE')}</p>
                  <p className="text-white text-sm not-italic leading-5 font-semibold">{seatInformation.seat_type}</p>
                  <p className="text-white text-sm not-italic leading-5 font-semibold">{seatInformation.seat_number}</p>
                </div>
                <div className="flex flex-col gap-1 z-[2]">
                  <p className="text-white opacity-70 text-sm leading-5 not-italic font-medium text-right">{t('HOME_ORDERID_TITLE')}</p>
                  <p className="text-white text-sm not-italic leading-5 font-semibold">{orderId}</p>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 flex flex-col items-center justify-center w-full z-[2]">
              <div className="relative w-[300px]">
                <div className="absolute inset-y-0 flex items-center pointer-events-none">
                  <SearchIcon className="ml-[6px] text-green-600" />
                </div>
                <input
                  type="search"
                  id="default-search"
                  ref={inputRef}
                  className="block w-full py-3 pl-[30px] pr-1 text-sm text-gray-400 border border-gray-300 rounded-lg bg-white focus:ring-green-600 focus:border-green-600 outline-none"
                  placeholder={t('HOME_SEARCH_PLACEHOLDER')}
                  onChange={handleSearch}
                  onFocus={() => setIsInputFocused(true)}
                />
              </div>
              {!isInputEmpty && isInputFocused && <SearchResult searchResult={searchResult} inputValue={input} />}
            </div>
            <div className="h-[22px]"></div>
          </div>
          <ListFoodSuggested />
          <Restaurant listRestaurant={listRestaurantData} stadiumData={stadiumData} />
        </div>
      )}
    </div>
  );
};

export default HomeTab;
