import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Welecome from './pages/Welcome';
import NewOrder from './pages/NewOrder';
import NotFound from './pages/NotFound';
import Menu from './pages/Menu';
import DeliveryInformation from './pages/DeliveryInformation';
import { QueryClient, QueryClientProvider } from 'react-query';

import { MenuProvider } from './context/MenuContext';
import { OrderProvider } from './context/OrderContext';
import { I18nProvider } from './context/I18n/I18n';
import OrderDetail from './pages/OrderDetail';

const queryClient = new QueryClient();

export default function App() {
  return (
    <div className="h-screen font-vn">
      <QueryClientProvider client={queryClient}>
        <I18nProvider>
          <MenuProvider>
            <OrderProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Welecome />} />
                  <Route path="/neworder/:id" element={<NewOrder />} />
                  <Route path="/menu/:id" element={<Menu />} />
                  <Route path="/delivery" element={<DeliveryInformation />} />
                  <Route path="/orderdetail/:id" element={<OrderDetail />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </BrowserRouter>
            </OrderProvider>
          </MenuProvider>
        </I18nProvider>
      </QueryClientProvider>
    </div>
  );
}
