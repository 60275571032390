import React from 'react';

interface ButtonProps {
  disabled?: boolean;
  type: 'primary' | 'secondary' | 'submit' | 'error';
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ disabled, type, onClick, children }) => {
  const buttonClasses: { [key in ButtonProps['type']]: string } = {
    primary: 'border-primary-gray text-black-700 w-full',
    error: 'border-primary-gray text-white w-full',
    secondary: 'bg-primary-green border-primary-green text-white w-full',
    submit: 'bg-primary-green border-primary-green text-white w-full',
  };

  const buttonClass = buttonClasses[type] || buttonClasses.primary;

  const buttonDisabledClass = type === 'error' ? 'bg-[#070807] border-[#0c100c] cursor' : disabled ? 'bg-[#C6ECCA] border-[#C6ECCA] cursor-not-allowed' : '';

  return (
    <button
      disabled={disabled}
      className={`px-18 py-2.5 rounded-lg border border-solid shadow-button text-base font-semibold leading-6 ${buttonClass} ${buttonDisabledClass}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
