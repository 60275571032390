import { format, setHours, setMinutes, setSeconds, isWithinInterval } from 'date-fns';
import { listRestaurantType } from '../interfaces/types';
import { toZonedTime } from 'date-fns-tz';

export const isRestaurantOnline = (restaurant: listRestaurantType) => {
  const now = new Date();
  const timeZone = 'Asia/Tokyo';
  const nowTokyo = toZonedTime(now, timeZone);

  const currentDay = format(nowTokyo, 'EEE').toUpperCase();
  const openingSchedule = restaurant.opening_schedule[currentDay];

  if (!openingSchedule || !openingSchedule.enabled) {
    return false;
  }

  const utcStartTime = toZonedTime(new Date(openingSchedule.utc_start_time), timeZone);
  const utcEndTime = toZonedTime(new Date(openingSchedule.utc_end_time), timeZone);

  const startTime = setSeconds(setMinutes(setHours(nowTokyo, utcStartTime.getHours()), utcStartTime.getMinutes()), utcStartTime.getSeconds());
  const endTime = setSeconds(setMinutes(setHours(nowTokyo, utcEndTime.getHours()), utcEndTime.getMinutes()), utcEndTime.getSeconds());

  const isWithinTimeRange = isWithinInterval(nowTokyo, { start: startTime, end: endTime });

  return isWithinTimeRange;
};

export const getBrowserLocale = () => {
  const browserLanguage = window.navigator.language;
  if (['ja'].includes(browserLanguage)) {
    return 'ja';
  } else {
    return 'en';
  }
};

export const formatPriceItem = (price: number) => {
  const formattedPriceItem = price.toLocaleString('jp');
  return formattedPriceItem;
};

export const getUTCDateStr = () => {
  const today = new Date();
  const yyyy = today.getUTCFullYear().toString();
  const mm = (today.getUTCMonth() + 1).toString().padStart(2, '0');
  const dd = today.getUTCDate().toString().padStart(2, '0');
  return `${yyyy}${mm}${dd}`;
};
