import React from 'react';
import RestaurantItem from './RestaurantItem';
import { Link, useLocation } from 'react-router-dom';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOrderContext } from '../../../context/OrderContext';
import { isRestaurantOnline } from '../../../utils';
import { listRestaurantType } from '../../../interfaces/types';
import queryString from 'query-string';
import { useMenuContext } from '../../../context/MenuContext';

interface ListRestaurantProps {
  list: listRestaurantType[];
}

const ListRestaurant: React.FC<ListRestaurantProps> = ({ list }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { orderDetails, updateOrderDetails } = useOrderContext();
  const { stadiumData } = useMenuContext();
  const queryParams = queryString.parse(location.search);

  const handleRestaurantClick = (restaurant: listRestaurantType) => {
    if (!isRestaurantOnline(restaurant)) {
      setDialogOpen(true);
    } else {
      const updatedOrderDetails = {
        ...orderDetails,
        foods: [],
        promotion_restaurant_id: '',
        promotion_foods: [],
      };
      if (updatedOrderDetails.hasOwnProperty('food_order_status')) {
        delete updatedOrderDetails.food_order_status;
      }
      if (updatedOrderDetails.hasOwnProperty('food_promotion_order_status')) {
        delete updatedOrderDetails.food_promotion_order_status;
      }
      updateOrderDetails(updatedOrderDetails);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const restaurantLink = (retaurantId: string) => {
    return queryString.stringifyUrl({
      url: `/menu/${retaurantId}`,
      query: queryParams,
    });
  };

  return (
    <div className="flex flex-col">
      {list.map((restaurant, index) => (
        <div key={index} className={index > 0 ? 'border-t border-gray-200 mx-4' : 'mx-4'}>
          <div className="cursor-pointer" onClick={() => handleRestaurantClick(restaurant)}>
            {isRestaurantOnline(restaurant) ? (
              <Link to={restaurantLink(restaurant.id)}>
                <RestaurantItem
                  name={restaurant.name}
                  itemCount={restaurant.sold}
                  location={restaurant.region}
                  describe={restaurant.categories}
                  image={restaurant.restaurant_profile_pic}
                  online={true}
                  enable_sales_count={stadiumData.enable_sales_count}
                />
              </Link>
            ) : (
              <RestaurantItem
                name={restaurant.name}
                itemCount={restaurant.sold}
                location={restaurant.region}
                describe={restaurant.categories}
                image={restaurant.restaurant_profile_pic}
                online={false}
                enable_sales_count={stadiumData.enable_sales_count}
              />
            )}
          </div>
        </div>
      ))}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}>
        <div className="p-6 flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <p className="text-black-900 text-lg font-semibold leading-7">{t('LISTRESTAURANT_MODAL_TITLE')}</p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ListRestaurant;
