import React from 'react';
import { CircularProgress } from '@mui/material';
import { ReactComponent as SleepIcon } from '../../../../assets/icon/sleep.svg';
import { useOrderContext } from '../../../../context/OrderContext';
import OrderList from './OrderList';
import { useTranslation } from 'react-i18next';

const OrderHistoryTab: React.FC<{ onStartOrder: () => void }> = ({ onStartOrder }) => {
  const { loadingOrder, orders } = useOrderContext();
  const { t } = useTranslation();

  return (
    <div className="relative min-h-[100vh]">
      {loadingOrder ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <CircularProgress color="success" size={65} />
        </div>
      ) : (
        <div className="flex flex-col h-[calc(100vh-64px)]">
          <div className="px-4 pt-6 pb-4 border-b flex justify-center">
            <p className="text-black-900 text-lg font-semibold leading-7">{t('HOME_TAB_ORDERHISTORY')}</p>
          </div>
          {orders?.length ? (
            <OrderList orders={orders} />
          ) : (
            <div className="flex flex-col justify-center items-center w-full h-full gap-4">
              <SleepIcon />
              <p className="text-black-400 text-base font-medium leading-6">{t('ORDER_HISTORY_TITLE')}</p>
              <button
                onClick={onStartOrder}
                className="w-fit text-white border border-green-600 bg-green-600 rounded-lg py-[10px] px-[18px] text-base font-semibold leading-6"
              >
                {t('START_ORDER')}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderHistoryTab;
