import { useRef } from 'react';
import { db } from '../../src/libs/firebase';
import { listRestaurantType, CreateOrderDTO } from '../../src/interfaces/types';
import firebase from 'firebase/compat/app';
import { isRestaurantOnline } from '../utils';

const useCreateOrder = () => {
  const errorOrder = useRef(true);

  const checkOrder = async (orderDetails: CreateOrderDTO): Promise<boolean> => {
    try {
      const queryRestaurant = await db.collection('meny_manage_restaurant_config').doc(orderDetails.restaurant_id).get();

      if (queryRestaurant.exists) {
        const restaurantData = queryRestaurant.data() as listRestaurantType;

        if (restaurantData && isRestaurantOnline(restaurantData)) {
          const queryFoods = await db.collection('stadium_manage_all_food_details').where('restaurantId', '==', orderDetails.restaurant_id).get();
          const queryCategories = await db.collection('stadium_manage_all_categories').where('restaurantId', '==', orderDetails.restaurant_id).get();

          const foods = queryFoods.docs.map((doc) => doc.data());
          const categories = queryCategories.docs.map((doc) => doc.data());

          for (const food of orderDetails.foods) {
            const restaurantFood = foods.find((f) => f.id === food.item_food_id);
            if (!restaurantFood || !restaurantFood.enabled) {
              sessionStorage.setItem('ErrorFood', food.item_name);
              return false;
            }
          }

          for (const food of orderDetails.foods) {
            const orderCategory = categories.find((f) => f.id === food.item_category_id);
            if (!orderCategory || orderCategory.category_show === 0) {
              sessionStorage.setItem('ErrorFood', orderCategory ? `${orderCategory.title}` : `Category`);
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.log('error:', error);
      return false;
    }
  };

  const createOrder = async (orderDetails: CreateOrderDTO, orderId: string) => {
    try {
      const isErrorOrder = await checkOrder(orderDetails);
      errorOrder.current = isErrorOrder;

      if (isErrorOrder && orderId) {
        await db
          .collection('stadium_manage_all_orders')
          .doc(orderId)
          .set({
            ...orderDetails,
            ordered_by: localStorage.getItem('uuid'),
            order_create_time: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }
    } catch (error) {
      console.error('Error creating and updating order document: ', error);
    }
  };

  return {
    createOrder,
    errorOrder,
  };
};

export default useCreateOrder;
