import React from 'react';
import { RestaurantWithFoodType, SearchResultType, listRestaurantType } from '../../../../../interfaces/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOrderContext } from '../../../../../context/OrderContext';
import queryString from 'query-string';

interface SearchResultProps {
  searchResult?: SearchResultType;
  inputValue: string;
}

const SearchResult: React.FC<SearchResultProps> = ({ searchResult = { restaurants: [], foods: [] }, inputValue }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { orderDetails, updateOrderDetails } = useOrderContext();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const filterFoodsByInput = (restaurant: RestaurantWithFoodType) => {
    return restaurant.food_details.filter((food) => food.enabled == true && food.name.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const handleFoodClick = (id: string) => {
    const updatedOrderDetails = {
      ...orderDetails,
      foods: [],
      promotion_restaurant_id: '',
      promotion_foods: [],
    };
    if (updatedOrderDetails.hasOwnProperty('food_order_status')) {
      delete updatedOrderDetails.food_order_status;
    }
    if (updatedOrderDetails.hasOwnProperty('food_promotion_order_status')) {
      delete updatedOrderDetails.food_promotion_order_status;
    }
    updateOrderDetails(updatedOrderDetails);
    const menuDetailLink = queryString.stringifyUrl({
      url: `/menu/${id}`,
      query: queryParams,
    });
    navigate(menuDetailLink);
  };

  const handleRestaurantClick = (retaurantId: string) => {
    const menuDetailLink = queryString.stringifyUrl({
      url: `/menu/${retaurantId}`,
      query: queryParams,
    });
    navigate(menuDetailLink);
  };

  return (
    <div className="absolute top-[55px] max-h-[320px] overflow-auto w-[70%] border rounded-md shadow-search no-scrollbar">
      {(searchResult && searchResult.foods.length > 0) || (searchResult && searchResult.restaurants.length > 0) ? (
        <div className="bg-white">
          {searchResult.restaurants.map((restaurant, index) => (
            <div key={index}>
              <ul>
                <li onClick={() => handleRestaurantClick(restaurant.id)}>
                  <div className="flex flex-1 gap-4 border-b p-3 items-center hover:bg-gray-300 cursor-pointer">
                    <img className="h-16 w-16 rounded-[50%]" src={restaurant.restaurant_profile_pic} alt="food" />
                    <div className="flex flex-col gap-1 max-w-[80%]">
                      <p className="text-black-800 text-base font-semibold leading-6">{restaurant.name}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ))}
          {searchResult.foods.map((restaurant, index) => (
            <div key={index}>
              <ul>
                {filterFoodsByInput(restaurant).map((food, foodIndex) => (
                  <li key={foodIndex} onClick={() => handleFoodClick(food.restaurantId)}>
                    <div className="flex flex-1 gap-4 border-b p-3 items-center hover:bg-gray-300 cursor-pointer">
                      <img className="h-16 w-16 rounded-[50%]" src={food.image_url} alt="food" />
                      <div className="flex flex-col gap-1 max-w-[80%]">
                        <p className="text-black-800 text-base font-semibold leading-6">{restaurant.name}</p>
                        <p className="text-sm font-medium leading-5 text-gray-500"> {food.name}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <div className="py-4 bg-white">
          <p className="text-black-800 text-base font-semibold leading-6 p-4">{t('HOME_SEARCH_RESULT')}</p>
        </div>
      )}
    </div>
  );
};

export default SearchResult;
