import React from 'react';
import { useTranslation } from 'react-i18next';
import { User_Rider } from '../../../interfaces/types';

interface RiderInForProps {
  riderInfor?: User_Rider;
}

const defaultImageURL = 'https://i.pinimg.com/736x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg';

const RiderInFor: React.FC<RiderInForProps> = ({ riderInfor }) => {

  const { t } = useTranslation();
  
  return (
    <div className="flex gap-4 items-center p-3 border border-neutral-300 rounded-lg ">
      <div className="flex gap-2 items-center">
        <img className="w-[40px] h-[40px] rounded-[1111111px]" src={riderInfor?.image || defaultImageURL} alt="Rider" />
        <div>
          <p className="text-black-500 text-xs font-semibold leading-[18px]">{t('ORDER_STATUS_YOUR_RIDER')}</p>
          <p className="text-black-700 text-sm font-normal leading-5">{riderInfor?.name}</p>
        </div>
      </div>
    </div>
  );
};

export default RiderInFor;
