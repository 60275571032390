import React from 'react';
import { ReactComponent as LocationIcon } from '../../../assets/icon/location.svg';
import { useTranslation } from 'react-i18next';
import { CategoriesType } from '../../../interfaces/types';

interface RestaurantItemProps {
  name: string;
  itemCount: number;
  location: string;
  describe: CategoriesType[];
  image: string;
  online: boolean;
  enable_sales_count: boolean;
}

const RestaurantItem: React.FC<RestaurantItemProps> = ({ name, itemCount, location, describe, image, online, enable_sales_count }) => {
  const { t } = useTranslation();
  const sortedDescribe = describe
    .filter((item) => item.category_show === 1)
    .slice()
    .sort((a, b) => a.order - b.order)
    .slice(0, 4);

  return (
    <div className={`py-4 flex gap-3 ${online ? '' : 'opacity-40'}`}>
      <img src={image} alt={name} className="w-[100px] h-[100px] shrink-0 rounded-lg" />
      <div className="flex flex-col gap-2">
        <p className="text-gray-800 text-base font-medium not-italic leading-6 text-clip max-w-[280px] overflow-hidden">{name}</p>
        {enable_sales_count && (
          <div className="text-gray-500 text-xs font-medium not-italic leading-[18px] flex gap-2">
            <p className="font-light">{t('HOME_SOLD_ITEM', { item: itemCount })}</p>
            <p>|</p>
            <LocationIcon />
            <p>
              {location} {t('ZONE')}
            </p>
          </div>
        )}
        <div className="flex flex-wrap gap-1">
          {sortedDescribe.map((description, index) => (
            <p
              key={index}
              className="text-green-700 text-xs not-italic font-medium leading-[18px] rounded-2xl bg-[#F2FBF3] px-2 py-[2px] max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {description.title}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RestaurantItem;
