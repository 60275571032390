import React from 'react';
import ConveyorBelt from './components/Conveyorbelt';
import { useTranslation } from 'react-i18next';

const ListFoodSuggested: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <p className="text-gray-900 text-lg font-semibold leading-7 pl-4">{t('HOME_SUGGESTED')}</p>
      <ConveyorBelt />
    </div>
  );
};

export default ListFoodSuggested;
