import { useQuery } from 'react-query';
import { BASE_URL } from '../configs/config';

const useGetSourceLang = (site: string) => {
  let url = `${BASE_URL}getSourceLang?site=${site}`;
  return useQuery(
    url,
    async () => {
      try {
        const response = await fetch(url);
        return response.json();
      } catch (error) {
      }
    },
    { retry: 2 }
  );
};

export default useGetSourceLang;
