import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { SITES } from '../../consts';
import useGetSourceLang from '../../hook/useGetSourceLang';
import { getBrowserLocale } from '../../utils';

i18n.use(initReactI18next).init({
  resources: {},
  lng: getBrowserLocale(),
  fallbackLng: 'ja',
});

export function I18nProvider({ children }: { children: ReactNode }) {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { data }: any = useGetSourceLang(SITES.CLIENT);

  useEffect(() => {
    if (data) {
      for (var key in data) {
        i18n.addResourceBundle(key, 'translation', data[key]);
      }
      setIsDataLoaded(true);
    }
  }, [data]);

  if (!isDataLoaded) return null;

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export default i18n;
