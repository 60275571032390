import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ReactComponent as ArrowLeft } from '../../../assets/icon/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import { FoodDetailItem, FoodItem, RestaurantWithFoodType } from '../../../interfaces/types';
import { ReactComponent as PlusIcon } from '../../../assets/icon/plus.svg';
import ItemCounter from './ItemCouter';
import Button from '../../../components/Button';
import { useMenuContext } from '../../../context/MenuContext';
import { useOrderContext } from '../../../context/OrderContext';
import { formatPriceItem } from '../../../utils';

interface AddPromotionProps {
  toggleDrawer: (newOpen: boolean) => () => void;
  handleDelivery: () => void;
  promotion_restaurant_id: string;
}

const AddPromotion: React.FC<AddPromotionProps> = ({ toggleDrawer, handleDelivery, promotion_restaurant_id }) => {
  const { t } = useTranslation();
  const [promotionRestaurantById, setPromotionRestaurantById] = useState<RestaurantWithFoodType>();
  const { findRestaurantById } = useMenuContext();
  const [foods, setFoods] = useState<FoodDetailItem[]>();
  const [categoryCounts, setCategoryCounts] = useState<{ [key: string]: number }>({});
  const [activeFood, setActiveFood] = useState<{ [key: string]: number }>({});
  const { updateOrderDetails, orderDetails } = useOrderContext();
  const [formDataDish, setFormDataDish] = useState(null);

  useEffect(() => {
    if (promotion_restaurant_id) {
      const newRestaurantByID = findRestaurantById(promotion_restaurant_id);
      if (newRestaurantByID !== undefined) {
        setPromotionRestaurantById(newRestaurantByID);
      } else {
        toggleDrawer(false);
      }
    }
  }, [promotion_restaurant_id]);

  useEffect(() => {
    if (promotionRestaurantById && promotionRestaurantById.categories) {
      const listFood = promotionRestaurantById.food_details.filter((food) => food.enabled);
      setFoods(listFood);
      const counts: { [key: string]: number } = {};
      let activeCat = '';

      promotionRestaurantById.categories
        .filter((category) => category.category_show === 1)
        .sort((a, b) => a.order - b.order)
        .forEach((category) => {
          counts[category.title] = 0;
          promotionRestaurantById.food_details.forEach((food) => {
            if (food.categories.includes(category.id) && food.enabled === true) {
              counts[category.title]++;
            }
          });

          if (!activeCat) {
            activeCat = category.title;
          }
        });
      setCategoryCounts(counts);
    }
  }, [promotionRestaurantById]);

  const handleAddFood = (data: any) => {
    const priceFood = parseFloat(data.price);
    const totalPrice = Number(priceFood.toFixed(2));
    const newData = {
      ...data,
      chooseToppings: [],
      id: data.id,
      category_id: data.food ? data.food.item_category_id : data.categories[0],
      quantity: 1,
      name: data.name,
      price: priceFood,
      total_price: totalPrice,
      sku: data.sku ?? '',
      tax_rate: data.tax_rate ?? '0',
    };
    handleFormSubmitDish(newData);
  };

  const handleFormSubmitDish = (data: any) => {
    setFormDataDish(data);
  };

  const handleSetCount = (newCount: number, itemId: string, categoryId: string) => {
    if (newCount === 0) {
      const updatedOrderDetails = orderDetails.promotion_foods.filter((food: FoodItem) => !(food.item_food_id === itemId && food.item_category_id === categoryId));
      updateOrderDetails({ ...orderDetails, promotion_foods: updatedOrderDetails });
    } else {
      const updatedOrderDetails = orderDetails.promotion_foods.map((food: FoodItem) => {
        if (food.item_food_id === itemId && food.item_category_id === categoryId) {
          return { ...food, item_quantity: newCount, item_subtotal: newCount * food.item_price };
        }
        return food;
      });
      updateOrderDetails({ ...orderDetails, promotion_foods: updatedOrderDetails });
    }
  };

  const findQuantityInOrderDetail = useMemo(() => {
    return (itemId: string, categoryId: string) => {
      const foundItem = orderDetails.promotion_foods.find((food) => food.item_food_id === itemId && food.item_category_id === categoryId);

      return foundItem ? foundItem.item_quantity : 0;
    };
  }, [orderDetails]);

  useEffect(() => {
    if (orderDetails.promotion_foods.length > 0) {
      const updatedActiveItems: { [key: string]: number } = {};
      orderDetails.promotion_foods.forEach((food) => {
        const key = `${food.item_food_id}_${food.item_category_id}`;
        updatedActiveItems[key] = food.item_quantity;
      });

      setActiveFood(updatedActiveItems);
    } else {
      setActiveFood({});
    }
  }, [orderDetails, formDataDish]);

  useEffect(() => {
    if (formDataDish) {
      addToCart(formDataDish);
    }
  }, [formDataDish]);

  const addToCart = (item: any) => {
    const existingItemIndex = orderDetails.promotion_foods.findIndex((existingItem) => {
      return existingItem.item_category_id === item.category_id && existingItem.item_food_id === item.id;
    });

    if (existingItemIndex !== -1) {
      const updatedFoods = [...orderDetails.promotion_foods];
      updatedFoods[existingItemIndex] = {
        item_category_id: item.category_id,
        item_food_id: item.id,
        item_price: item.price,
        item_name: item.name,
        item_note: item.note ?? '',
        item_quantity: item.quantity,
        item_subtotal: item.total_price,
        sku: item.sku,
        tax_rate: item.tax_rate,
        item_customize_settings: item.chooseToppings,
      };
      updateOrderDetails({ ...orderDetails, promotion_foods: updatedFoods });
    } else {
      const newItemData: FoodItem = {
        item_category_id: item.category_id,
        item_food_id: item.id,
        item_price: item.price,
        item_name: item.name,
        item_note: item.note ?? '',
        item_quantity: item.quantity,
        item_subtotal: item.total_price,
        sku: item.sku,
        tax_rate: item.tax_rate,
        item_customize_settings: item.chooseToppings,
      };
      updateOrderDetails({ ...orderDetails, promotion_foods: [...orderDetails.promotion_foods, newItemData] });
    }
  };

  return (
    <div className="w-[100vw] h-screen bg-black-0 overflow-hidden">
      <div className="max-w-[672px] h-screen mx-auto bg-white overflow-auto">
        <div className="z-10 flex w-full sticky top-0 bg-white gap-2 items-center px-4 border-b">
          <button className="p-3" onClick={toggleDrawer(false)}>
            <ArrowLeft />
          </button>
          <p className="text-gray-700 text-base font-medium leading-6">{t('COMPLETE_ORDER')}</p>
        </div>

        <div className="h-max" style={{ minHeight: 'calc(100vh - 120px)' }}>
          {promotionRestaurantById &&
            promotionRestaurantById.categories
              .filter((category) => categoryCounts[category.title] > 0)
              .sort((a, b) => a.order - b.order)
              .map((category) => (
                <div key={category.id} className="px-4 pt-4">
                  <h2 className="text-gray-500 text-base not-italic font-medium leading-6 pb-1">
                    {category.title} ({categoryCounts[category.title]})
                  </h2>
                  <div className="overflow-y-auto bg-white grid grid-cols-2 gap-4">
                    {foods &&
                      foods
                        .filter((food) => food.categories.includes(category.id))
                        .map((food) => (
                          <div key={food.id} className="flex flex-col" onClick={() => {}}>
                            <div className="relative w-full" style={{ paddingTop: '75%' }}>
                              <img alt={food.id} className="rounded-lg absolute inset-0 w-full h-full object-cover" src={food.image_url} />
                              <div className="absolute bottom-0 right-0 m-2">
                                {activeFood[`${food.id}_${category.id}`] ? (
                                  <div className="p-[6px] rounded-full bg-white text-white flex items-center justify-center">
                                    <ItemCounter
                                      count={findQuantityInOrderDetail(food.id, category.id)}
                                      setCount={(newCount) => handleSetCount(newCount, food.id, category.id)}
                                      initialCountItem={0}
                                    />
                                  </div>
                                ) : (
                                  <button
                                    className="w-8 h-8 rounded-full bg-white text-white flex items-center justify-center"
                                    onClick={(e) => {
                                      handleAddFood(food);
                                    }}
                                  >
                                    <PlusIcon />
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col gap-1 overflow-hidden pt-1">
                              <p className="line-clamp-2">{food.name}</p>
                              <p className="text-green-500 font-medium">¥ {formatPriceItem(food.price)}</p>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              ))}
        </div>

        <div className="p-2 bg-white sticky bottom-0">
          <Button onClick={handleDelivery} type="error">
            {orderDetails.promotion_foods.length > 0 ? t('COMPLETE_ORDER') : t('NO_THANKS')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddPromotion;
