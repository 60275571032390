import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { db, storage } from '../../libs/firebase';
import { listRestaurantType, CategoriesType, FoodDetailItem, RestaurantWithFoodType, IStadium, MenuContextValue, SeatInformation } from '../../interfaces/types';
import { collection, query, where, getCountFromServer } from 'firebase/firestore';
import { BillStatus, TABS } from '../../consts';
import Papa from 'papaparse';

type ParsedCSVData = string[][];

const initStadiumData = {
  administrators: [],
  enable_sales_count: false,
  seat_number: '',
  fileUrl: '',
  id: '',
  isSeeAll: false,
  isStadiumPromotion: false,
  last_update_date: '',
  latest_id: 0,
  name: '',
  promotion_restaurant_id: '',
  service_name: '',
  stadium_profile_pic: '',
  status: '',
  riders: [],
};

const getStadiumManageFoodDetails = () => {
  return db.collection('stadium_manage_all_food_details');
};

const getManageAllCategories = () => {
  return db.collection('stadium_manage_all_categories');
};

const getRestaurantConfig = () => {
  return db.collection('meny_manage_restaurant_config');
};

const getStadiumConfig = () => {
  return db.collection('stadium_manage');
};

const MenuContext = createContext<MenuContextValue>({
  listRestaurantData: [],
  listRestaurantWithFood: [],
  loading: true,
  loadingFoods: true,
  findRestaurantById: () => {},
  setFetchData: () => {},
  setListRestaurantWithFood: () => {},
  stadiumData: initStadiumData,
  activeTab: TABS.HOME,
  setActiveTab: () => {},
  seatInformation: {
    seat_type: '',
    seat_number: '',
  },
});

const MenuProvider = ({ children }: { children: ReactNode }) => {
  const [activeTab, setActiveTab] = useState(TABS.HOME);
  const [loading, setLoading] = useState(true);
  const [loadingFoods, setLoadingFoods] = useState(true);
  const [fetchData, setFetchData] = useState(false);
  const stadiumID = sessionStorage.getItem('stadiumID');
  const seatId = sessionStorage.getItem('seatId') ?? '';
  const [seatInformation, setSeatInformation] = useState<SeatInformation>({
    seat_type: '',
    seat_number: '',
  });

  const [listRestaurantData, setListRestaurantData] = useState<listRestaurantType[]>([]);
  const [stadiumData, setStadiumData] = useState<IStadium>(initStadiumData);
  const [listRestaurantWithFood, setListRestaurantWithFood] = useState<RestaurantWithFoodType[]>([]);

  const [restaurantFoodMap, setRestaurantFoodMap] = useState<{ [key: string]: FoodDetailItem[] }>({});

  const getSoldCount = async (restaurantId: string): Promise<number> => {
    const ordersCollectionRef = collection(db, 'stadium_manage_all_orders');

    const normalOrdersQuery = query(ordersCollectionRef, where('restaurant_id', '==', restaurantId), where('bill_status', '==', BillStatus.CLEARED));

    const promotionOrdersQuery = query(ordersCollectionRef, where('promotion_restaurant_id', '==', restaurantId), where('bill_status', '==', BillStatus.CLEARED));

    const normalOrdersSnapshot = await getCountFromServer(normalOrdersQuery);
    const promotionOrdersSnapshot = await getCountFromServer(promotionOrdersQuery);

    return normalOrdersSnapshot.data().count + promotionOrdersSnapshot.data().count;
  };

  const fetchRestaurantData = useCallback(async () => {
    try {
      setLoading(true);
      let restaurantConfigData: IStadium;
      if (stadiumID) {
        const restaurantConfig = await getStadiumConfig().doc(stadiumID).get();
        const result = restaurantConfig.exists ? restaurantConfig.data() : null;
        restaurantConfigData = result as IStadium;
        setStadiumData(result as IStadium);
      }
      const [queryRestaurantConfig, querycategories] = await Promise.all([
        getRestaurantConfig().where('stadium_id', '==', stadiumID).where('status', '==', 'active').get(),
        getManageAllCategories().get(),
      ]);

      const categoryDocs: CategoriesType[] = querycategories.docs.map((doc) => ({
        id: doc.id,
        category_show: doc.data().category_show,
        foooIds: doc.data().foodIds,
        order: doc.data().order,
        restaurantId: doc.data().restaurantId,
        title: doc.data().title,
      }));

      let listRestaurant = await Promise.all(
        queryRestaurantConfig.docs.map(async (doc) => {
          const data = doc.data();
          const soldCount = await getSoldCount(doc.id);

          const restaurant: listRestaurantType = {
            id: doc.id,
            name: data.name,
            region: data.region,
            restaurant_profile_pic: data.restaurant_profile_pic,
            categories: categoryDocs.filter((category) => category.category_show === 1 && category.restaurantId === doc.id),
            opening_schedule: data.opening_schedule,
            isStadiumPromotion: restaurantConfigData?.isStadiumPromotion ?? false,
            promotion_restaurant_id: restaurantConfigData?.promotion_restaurant_id ?? '',
            sold: soldCount,
          };

          return restaurant;
        })
      );
      listRestaurant.sort((a, b) => b.sold - a.sold);
      setListRestaurantData(listRestaurant);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [stadiumID]);

  const fetchFoodsData = useCallback(async () => {
    try {
      setLoadingFoods(true);
      if (listRestaurantData.length > 0) {
        const restaurantFoodMap: Record<string, FoodDetailItem[]> = {};
        const addedFoodIds: Record<string, boolean> = {};

        for (const restaurant of listRestaurantData) {
          const categoryIds = restaurant.categories.map((categoryDoc) => categoryDoc.id);
          const foodChunks = chunkArray(categoryIds, 20);

          await Promise.all(
            foodChunks.map(async (chunk) => {
              const foodQuerySnapshot = await getStadiumManageFoodDetails()
                .where('restaurantId', '==', restaurant.id)
                .where('categories', 'array-contains-any', chunk)
                .orderBy('create_time', 'desc')
                .get();

              for (const foodDoc of foodQuerySnapshot.docs) {
                const foodData = foodDoc.data();

                const foodDetail: FoodDetailItem = {
                  enabled: foodData.enabled,
                  enabled_note: foodData.enabled_note,
                  isEnableStockCount: foodData.isEnableStockCount,
                  description: foodData.description,
                  food_show_status: foodData.food_show_status,
                  unit_price: foodData.unit_price,
                  restaurantId: foodData.restaurantId,
                  customize_group_settings: foodData.customize_group_settings,
                  id: foodData.id,
                  price: foodData.price,
                  image_url: foodData.image_url,
                  name: foodData.name,
                  food_id: foodData.food_id,
                  categories: foodData.categories,
                  sku: foodData.sku,
                  tax_rate: foodData.tax_rate,
                };

                if (!addedFoodIds[foodDetail.id]) {
                  if (!restaurantFoodMap[foodDetail.restaurantId]) {
                    restaurantFoodMap[foodDetail.restaurantId] = [];
                  }
                  restaurantFoodMap[foodDetail.restaurantId].push(foodDetail);
                  addedFoodIds[foodDetail.id] = true;
                }
              }
            })
          );
        }
        setRestaurantFoodMap(restaurantFoodMap);
      }
    } catch (error) {
      console.error('Error fetching and processing food data:', error);
    } finally {
      setLoadingFoods(false);
    }
  }, [listRestaurantData]);

  function chunkArray(categories: any, size: number) {
    const chunkedArr = [];
    let index = 0;
    while (index < categories.length) {
      chunkedArr.push(categories.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  }

  const mergeRestaurantData = () => {
    const updatedListRestaurantData: RestaurantWithFoodType[] = listRestaurantData.map((restaurant) => {
      if (restaurant.id in restaurantFoodMap) {
        const filteredFoods = restaurantFoodMap[restaurant.id].filter((food) =>
          food.categories.some((foodCategory) => restaurant.categories.some((restaurantCategory) => restaurantCategory.id === foodCategory))
        );

        return {
          ...restaurant,
          food_details: filteredFoods,
        };
      } else {
        return {
          ...restaurant,
          food_details: [],
        };
      }
    });

    setListRestaurantWithFood(updatedListRestaurantData);
  };

  const findRestaurantById = (id: string): RestaurantWithFoodType | undefined => {
    const restaurant = listRestaurantWithFood.find((restaurant) => restaurant.id === id);
    return restaurant ? restaurant : undefined;
  };

  const parseCSV = (file: string): Promise<ParsedCSVData> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (results) => {
          resolve(results.data as ParsedCSVData);
        },
        header: false,
        error: (error: any) => {
          reject(error);
        },
      });
    });
  };

  const fetchCSVFromStorage = async (stadiumId: string): Promise<ParsedCSVData> => {
    try {
      const storageRef = storage.ref(`stadium/${stadiumId}`);
      const url = await storageRef.getDownloadURL();

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.text();
      return await parseCSV(data);
    } catch (error) {
      console.error('Error fetching or parsing CSV:', error);
      return [];
    }
  };

  const findRowByColumnValue = (data: ParsedCSVData, columnIndex: number, value: string): string[] | null => {
    for (const row of data) {
      if (row[columnIndex] === value) {
        return row;
      }
    }
    return null;
  };

  const getRowById = async (stadiumId: string, searchValue: string): Promise<string[] | null> => {
    try {
      const data = await fetchCSVFromStorage(stadiumId);

      const columnIndex = data[0].length - 1;
      return findRowByColumnValue(data, columnIndex, searchValue);
    } catch (error) {
      return null;
    }
  };

  const updateSeatInformation = async () => {
    const stadium_id = sessionStorage.getItem('stadiumID') ?? '';
    const information = await getRowById(stadium_id, seatId);
    setSeatInformation({
      seat_type: information?.[1] ?? '',
      seat_number: `${information?.[2] ?? ''}: ${information?.[3] ?? ''}`,
    });
  };

  useEffect(() => {
    const fetchSeatInformation = async () => {
      if(seatId && seatId !== ''){
      await updateSeatInformation();}
    };

    fetchSeatInformation();
  }, [seatId]);

  useEffect(() => {
    fetchRestaurantData();
  }, [stadiumID, fetchData]);

  useEffect(() => {
    fetchFoodsData();
  }, [listRestaurantData]);

  useEffect(() => {
    mergeRestaurantData();
  }, [restaurantFoodMap]);

  return (
    <MenuContext.Provider
      value={{
        loading,
        loadingFoods,
        listRestaurantData,
        listRestaurantWithFood,
        findRestaurantById,
        setFetchData,
        setListRestaurantWithFood,
        stadiumData,
        activeTab,
        setActiveTab,
        seatInformation,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

const useMenuContext = () => {
  const context = useContext(MenuContext);
  return context;
};

export { MenuProvider, useMenuContext };
