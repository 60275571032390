import React, { useRef } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListRestaurant from './components/ListRestaurant';
import { IStadium, listRestaurantType } from '../../interfaces/types';
import { useTranslation } from 'react-i18next';

interface RestaurantProps {
  listRestaurant: listRestaurantType[];
  stadiumData: IStadium;
}

const Restaurant: React.FC<RestaurantProps> = ({ listRestaurant, stadiumData }) => {
  const { t } = useTranslation();
  const listRef = useRef<HTMLDivElement>(null);

  const scrollToList = () => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="mb-[70px]">
      <div className="flex justify-between px-4 items-center">
        <p className="text-gray-900 text-lg font-semibold leading-7">{t('HOME_RESTAURANTS')}</p>
        {stadiumData && stadiumData.isSeeAll && (
          <button onClick={scrollToList} className="flex gap-2">
            <p className="text-green-700 text-sm font-semibold leading-5">{t('HOME_BUTTON_SEEALL')}</p>
            <ArrowForwardIosIcon className="text-green-700" fontSize="small" />
          </button>
        )}
      </div>
      <div ref={listRef}>
        <ListRestaurant list={listRestaurant} />
      </div>
    </div>
  );
};

export default Restaurant;
