import React, { useState } from 'react';
import { ReactComponent as MinusIcon } from '../../../assets/icon/minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icon/plus.svg';

interface ItemCounterProps {
  count: number;
  initialCountItem: number;
  setCount: (count: number) => void;
}

const ItemCounter: React.FC<ItemCounterProps> = ({ count, setCount, initialCountItem }) => {
  const handleIncrement = () => {
    const newCount = count + 1;
    setCount(newCount);
  };

  const handleDecrement = () => {
    if (count > initialCountItem) {
      const newCount = count - 1;
      setCount(newCount);
    }
  };

  return (
    <div className="flex items-center gap-1">
      <button type="button" onClick={handleDecrement} className="w-[26px] h-[26px] rounded-lg border border-neutral-300">
        <MinusIcon className="h-full w-full" />
      </button>
      <span className="px-2 text-center text-base font-semibold leading-6 text-gray-700">{count}</span>
      <button type="button" onClick={handleIncrement} className="w-[26px] h-[26px] rounded-lg border border-neutral-300">
        <PlusIcon className="h-full w-full" />
      </button>
    </div>
  );
};

export default ItemCounter;
