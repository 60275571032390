import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReactComponent as ArrowLeft } from '../../../assets/icon/arrow-left.svg';
import Button from '../../../components/Button';
import ItemCounter from './ItemCouter';
import { useTranslation } from 'react-i18next';
import { formatPriceItem } from '../../../utils';

interface CustomizeDishProps {
  toggleDrawer: (newOpen: boolean) => () => void;
  onSubmit: SubmitHandler<FormData>;
  dishData: any;
}

const schema = yup.object().shape({
  chooseToppings: yup.array().of(yup.string()).max(3, 'Select up to three toppings'),
  note: yup.string(),
});

const CustomizeDish: React.FC<CustomizeDishProps> = ({ toggleDrawer, onSubmit, dishData }) => {
  const [charCount, setCharCount] = useState(0);
  const [quantity, setQuantity] = useState<number>(1);
  const [selectedToppings, setSelectedToppings] = useState<string[]>([]);
  const [priceFood, setPriceFood] = useState(0);
  const totalPrice = Number((priceFood * quantity).toFixed(2));
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      note: '',
      chooseToppings: dishData.food ? dishData.food.item_customize_settings.map((setting: any) => setting.name) : [],
    },
  });

  const handleCustomDish = (data: any) => {
    const selectedToppingsData = data.chooseToppings.map((toppingName: string) => {
      const selectedTopping = dishData.customize_group_settings.find((option: any) => option.name === toppingName);
      return { name: selectedTopping.name, price: selectedTopping.price };
    });

    const newData = {
      ...data,
      chooseToppings: selectedToppingsData,
      id: dishData.id,
      category_id: dishData.food ? dishData.food.item_category_id : dishData.categories[0],
      quantity: quantity,
      name: dishData.name,
      price: priceFood,
      total_price: totalPrice,
      sku: dishData.sku ?? '',
      tax_rate: dishData.tax_rate ?? '0',
    };
    onSubmit(newData);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setCharCount(inputValue.length);
    if (inputValue.length > 200) {
      event.target.value = inputValue.slice(0, 200);
      setCharCount(200);
    }
  };

  const handleToppingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (event.target.checked) {
      setSelectedToppings((prevState) => [...prevState, value]);
    } else {
      setSelectedToppings((prevState) => prevState.filter((topping) => topping !== value));
    }
  };

  useEffect(() => {
    let totalToppingsPrice = 0;
    selectedToppings.forEach((toppingName) => {
      const selectedTopping = dishData.customize_group_settings.find((option: any) => option.name === toppingName);
      if (selectedTopping && typeof selectedTopping.price === 'string') {
        totalToppingsPrice += parseFloat(selectedTopping.price);
      } else {
        totalToppingsPrice += selectedTopping.price;
      }
    });

    if (dishData.price && typeof dishData.price === 'string') {
      const newprice = parseFloat(dishData.price);
      const newTotalPrice = newprice + totalToppingsPrice;
      setPriceFood(newTotalPrice);
    } else {
      const newTotalPrice = dishData.price + totalToppingsPrice;
      setPriceFood(newTotalPrice);
    }
  }, [selectedToppings, dishData.price, dishData.customize_group_settings]);

  useEffect(() => {
    if (dishData.food) {
      const selectedToppings = dishData.food.item_customize_settings.map((setting: any) => setting.name);
      setSelectedToppings(selectedToppings);
      setValue('note', dishData.food.item_note ?? '');
      setQuantity(dishData.food.item_quantity);
    }
  }, [dishData]);

  return (
    <div className="w-[100vw] h-[full] bg-black-0 overflow-hidden">
      <div className="max-w-[672px] h-screen mx-auto bg-white overflow-auto">
        <div className="flex w-full justify-center sticky top-0 bg-white gap-2 items-center px-4 border-b">
          <button className="absolute left-3 p-3" onClick={toggleDrawer(false)}>
            <ArrowLeft />
          </button>
          <p className="text-gray-700 text-base font-medium leading-6 p-3">{t('DISH_TITLE')}</p>
        </div>

        <div className="p-4 flex flex-col gap-4">
          <div className="flex gap-4">
            <img className="rounded-lg w-[80px]" src={dishData.image_url} alt={dishData.name} />
            <div className="flex flex-col">
              <p className="text-black-800 not-italic text-base leading-6 font-semibold">{dishData.name}</p>
              <div className="flex gap-1 text-sm leading-5">
                <p className="text-green-500 font-medium">¥ {formatPriceItem(dishData.price)}</p>
                <p className="text-black-400 font-light">{t('TAX_INCLUDED')}</p>
              </div>
            </div>
          </div>
          <p className="text-black-400 not-italic font-normal text-xs leading-[150%]">{dishData.description}</p>
        </div>

        <form className="w-full flex flex-col gap-2" onSubmit={handleSubmit(handleCustomDish)}>
          <div>
            {dishData.customize_group_settings.length > 0 && (
              <div className="py-4 border-b">
                <div className="px-4">
                  <label className="text-gray-800 text-base font-semibold leading-6">{t('DISH_TOPPING')}</label>
                  <div className="flex gap-2 text-xs font-normal leading-[18px] items-center">
                    <p className="text-green-500">{t('DISH_TOPPING_OPTIONAL')}</p>
                    <p className="text-neutral-300">|</p>
                    <p className={errors.chooseToppings?.message ? 'text-red-500' : 'text-black-500'}>{t('DISH_TOPPING_SELECT')}</p>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col px-4">
              {dishData &&
                dishData.customize_group_settings.map((option: any, index: any) => (
                  <div key={index} className="flex justify-between border-b gap-2 items-center py-4">
                    <label htmlFor={`option_${index}`} className="flex justify-between w-full items-center text-sm not-italic leading-5">
                      <p className="text-gray-700 font-normal">{option.name}</p>
                      <p>¥ {formatPriceItem(option.price)}</p>
                    </label>
                    <input
                      className="w-4 h-4"
                      type="checkbox"
                      id={`option_${index}`}
                      value={option.name}
                      {...register(`chooseToppings`)}
                      checked={selectedToppings.includes(option.name)}
                      onChange={handleToppingsChange}
                    />
                  </div>
                ))}
            </div>
          </div>

          {dishData.enabled_note && (
            <div className="flex flex-col pt-2 pb-[120px]">
              <div className="px-4 py-3 border-b">
                <p className="text-gray-800 text-base font-semibold leading-6">{t('MENU_BUTTON_NOTE')}</p>
                <p className="text-green-500 text-xs">{t('DISH_TOPPING_OPTIONAL')}</p>
              </div>
              <div className="p-4">
                <textarea
                  className="border w-full rounded-lg border-neutral-300 h-[150px] py-3 px-4 focus:border-green-500 focus:outline-none active:border-green-500"
                  placeholder={t('DISH_NOTE_PLACEHOLDER')}
                  {...register('note')}
                  onChange={handleInputChange}
                ></textarea>
                <p className="text-black-600 text-sm font-normal leading-5">{charCount}/200</p>
              </div>
            </div>
          )}

          <div className="flex flex-col gap-3 fixed bottom-0 bg-white w-full max-w-[672px] p-4">
            <div className="flex justify-between">
              <label className="text-base font-semibold leading-6 text-gray-700">{t('DISH_ITEM_QUANTITY')}</label>
              <ItemCounter count={quantity} setCount={setQuantity} initialCountItem={1} />
            </div>
            {dishData.food ? (
              <Button type="submit">{t('DISS_BUTTON_UPDATETOCARD')}</Button>
            ) : (
              <Button type="submit">
                {t('DISS_BUTTON_ADDTOCARD')} ¥ {formatPriceItem(totalPrice)}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomizeDish;
