import React from 'react';
import { useMenuContext } from '../../../context/MenuContext';
import { Link, useLocation } from 'react-router-dom';
import { isRestaurantOnline } from '../../../utils';
import { useOrderContext } from '../../../context/OrderContext';
import queryString from 'query-string';
import RestaurantItem from './RestaurantItem';

const ConveyorBelt: React.FC = () => {
  const { listRestaurantData, stadiumData } = useMenuContext();
  const location = useLocation();
  const { orderDetails, updateOrderDetails } = useOrderContext();
  const queryParams = queryString.parse(location.search);

  const onlineRestaurants = listRestaurantData.filter(isRestaurantOnline);

  const handleRestaurantClick = () => {
    const updatedOrderDetails = {
      ...orderDetails,
      foods: [],
      promotion_restaurant_id: '',
      promotion_foods: [],
    };
    if (updatedOrderDetails.hasOwnProperty('food_order_status')) {
      delete updatedOrderDetails.food_order_status;
    }
    if (updatedOrderDetails.hasOwnProperty('food_promotion_order_status')) {
      delete updatedOrderDetails.food_promotion_order_status;
    }
    updateOrderDetails(updatedOrderDetails);
  };

  const foodLink = (foodId: string) => {
    return queryString.stringifyUrl({
      url: `/menu/${foodId}`,
      query: queryParams,
    });
  };

  return (
    <div className="flex overflow-x-auto no-scrollbar">
      {onlineRestaurants.map((restaurant, index) => (
        <div onClick={handleRestaurantClick} key={index}>
          <Link to={foodLink(restaurant.id)}>
            <RestaurantItem
              name={restaurant.name}
              location={restaurant.region}
              image={restaurant.restaurant_profile_pic}
              itemCount={restaurant.sold}
              enable_sales_count={stadiumData.enable_sales_count}
            />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ConveyorBelt;
