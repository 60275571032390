import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { Drawer } from '@mui/material';
import PAYMENT from '../../DeliveryInformation/components/Payment';
import { SHIPPING_FEE } from '../../../consts';

interface PaymentBacklogProps {
  orderDetail: any;
}

const PaymentBacklog: React.FC<PaymentBacklogProps> = ({ orderDetail }) => {
  const { t } = useTranslation();
  const [openPayment, setOpenPayment] = useState(false);

  const [totalPrice] = useMemo(() => {
    if (!orderDetail) return [null, null, 0];

    const totalPrice =
      (orderDetail.foods?.reduce((total: any, food: any) => total + (parseFloat(food.item_subtotal) || 0), 0) || 0) +
      (orderDetail.promotion_foods?.reduce((total: any, food: any) => total + (parseFloat(food.item_subtotal) || 0), 0) || 0);
    const totalPayment = totalPrice + SHIPPING_FEE;
    const formattedPrice = totalPayment.toLocaleString('jp');
    return [formattedPrice];
  }, [orderDetail]);

  const handlePlaceOrder = () => {
    setOpenPayment(true);
  };

  const toggleDrawerPayment = (newOpen: boolean) => () => {
    setOpenPayment(newOpen);
  };

  return (
    <div className="flex flex-col gap-2 items-center">
      <p className="text-black-500 font-normal text-sm leading-5">{t('CONTINUE_PAY')}</p>
      <Button onClick={handlePlaceOrder} type="submit">
        {t('COMPLETE_PAYMENT')}
      </Button>
      <Drawer anchor={'right'} transitionDuration={600} open={openPayment} onClose={toggleDrawerPayment(false)}>
        <PAYMENT toggleDrawer={toggleDrawerPayment} totalPrice={totalPrice} orderId={orderDetail.id} />
      </Drawer>
    </div>
  );
};

export default PaymentBacklog;
