import React from 'react';

function NotFound() {
  return (
    <div className="h-full flex items-center justify-center bg-black-0">
      <img src="https://lh6.googleusercontent.com/Bu-pRqU_tWZV7O3rJ5nV1P6NjqFnnAs8kVLC5VGz_Kf7ws0nDUXoGTc7pP87tyUCfu8VyXi0YviIm7CxAISDr2lJSwWwXQxxz98qxVfMcKTJfLPqbcfhn-QEeOowjrlwX1LYDFJN"></img>
    </div>
  );
}

export default NotFound;
