import React from 'react';

const WelcomeLoader = () => {
  return (
    <div className="bg-black-1 h-full w-full px-4 flex items-center justify-center">
      <div className="bg-white px-4 py-5 w-full max-w-2xl rounded-xl shadow-modalWhite">
        <div className="flex gap-1 flex-col pb-6">
          <div className="flex justify-between">
            <div className="h-7 w-[25%] bg-gray-200 rounded"></div>
            <button>
              <div className=" bg-gray-200 rounded"></div>
            </button>
          </div>
          <div className="h-7 w-[75%] bg-gray-200 rounded"></div>
          <div className="h-7 w-[75%] bg-gray-200 rounded"></div>
          <div className="h-7 w-[75%] bg-gray-200 rounded"></div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="h-[40px] w-[100%] bg-gray-200 rounded"></div>
          <div className="h-[40px] w-[100%] bg-gray-200 rounded"></div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeLoader;
