import React from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icon/search.svg';
import { useTranslation } from 'react-i18next';

const FindRider: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2 items-center">
      <SearchIcon className='text-black-500' />
      <p className="text-black-500 font-normal text-sm leading-5">{t("ORDER_STATUS_FIND_RIDER")}</p>
    </div>
  );
};

export default FindRider;
